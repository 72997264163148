export default class User {
    email: string
    authorityRole: string
    authorityRoleName :string
    authorityLevel: number | null
    displayName: string
    isDeleted: boolean
    companies: string | string[]
    brands: string | string[]
    facilities: string | string[]
    companyNames: string
    brandNames: string
    facilityNames: string

    constructor(email: string, authorityRole: string, displayName: string
        , companies: string | string[], brands: string | string[], facilities: string | string[]
        , isDeleted: number,  authorityRoleName:string = ''
        , companyNames: string[] = [], brandNames: string[] = [], facilityNames: string[] = []
        , authorityLevel: number | null = null) {
        this.email = email
        this.authorityRole = authorityRole
        this.authorityRoleName = authorityRoleName
        this.authorityLevel = authorityLevel
        this.displayName = displayName
        this.companies = companies.length == 1 ? companies[0] : companies
        // this.brands = brands.length == 1 ? brands[0] : brands
        this.brands = brands
        // this.facilities = facilities.length == 1 ? facilities[0] : facilities
        this.facilities = facilities
        this.companyNames = companyNames.join(',')
        this.brandNames = brandNames.join(',')
        this.facilityNames = facilityNames.join(',')
        this.isDeleted = isDeleted === 1
    }
}