import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/ja'
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

const app = createApp(App)

app.use(ElementPlus, { locale })
app.use(router).mount('#app')
