import axios, { AxiosInstance } from 'axios';

const httpClient: AxiosInstance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api`,
    headers: {
        'Content-type': 'application/json',
    }
})

httpClient.interceptors.request.use(async conf => {

    const token = sessionStorage.getItem('spf.refresh_token')
    if (token === null || token === '' || token === 'undefined') {
        // token not found
        // トークンが存在しない場合はログイン画面に遷移させる
        delete sessionStorage['spf.id_token']
        delete sessionStorage['spf.refresh_token']
        window.location.href = '/maintenance/v1/login'
    }
    
    conf.headers['x-spf-auth'] = sessionStorage.getItem('spf.id_token')
    conf.headers['x-spf-auth-pid'] = sessionStorage.getItem('spf.auth.pid')
    return conf
})

httpClient.interceptors.response.use(async response => { return response },
async error => {
    if (error.response && error.response.status != 401) {
        return Promise.reject(error)
    }

    const token = sessionStorage.getItem('spf.refresh_token')
    if (token === null || token === '') {
        // token not found
        // トークンが存在しない場合はログイン画面に遷移させる
        window.location.href = '/maintenance/v1/login'
        //return Promise.reject(error)
    }

    // トークンの有効期限切れの場合は 401 が返却されるのでトークン更新する
    const params = new URLSearchParams();
    params.append('refresh_token', token as string)
    const clientId = sessionStorage.getItem('spf.auth.clientID')
    params.append('client_id', clientId ?? '')
    params.append('grant_type', 'refresh_token')

    const domain = sessionStorage.getItem('spf.auth.domain')
    const region = sessionStorage.getItem('spf.auth.region')
    // cognito からアクセストークン取得して再度リクエストした結果を返却
    return axios.post<any>(`https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`, params , {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
    .then(res => {
        // token refreshed

        sessionStorage.setItem('spf.id_token', res.data.id_token)

        // 再リクエスト (通常の httpClient ではないので、これで失敗したらトークン再設定はなし)
        error.response.config.headers['x-spf-auth'] = sessionStorage.getItem('spf.id_token')
        //return Promise.resolve(axios.request(error.response.config))
        return axios.request(error.response.config)
    })
    .catch(reason => {
        // token refresh error
        delete sessionStorage['spf.id_token']
        delete sessionStorage['spf.refresh_token']
        window.location.href = '/maintenance/v1/login'
        //return Promise.reject(error)
    })

})

export default httpClient;
