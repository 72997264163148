
import { defineComponent, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TheHeader from '../common/TheHeader.vue'
import TheMenu from '../common/TheMenu.vue'
import User from '@/models/user'
import http from "@/modules/httpclient"

// export default {
//     //name: 'layout',
//     components: {
//         TheHeader,
//         TheMenu,
//     },
// }
export default defineComponent({
    components: {
        TheHeader,
        TheMenu,
    },
    setup() {
    const router = useRouter()
    const user = reactive<User>(new User('', '', '', [], [], [], 0, ''))

    const getMe = async () => {
        try{
            const res = await http.get('/v1/me')
            const data = res.data.user
            user.email = data.email
            user.displayName = data.displayName
            user.authorityRole = data.authorityRole
            user.authorityLevel = data.authorityLevel
        }
        catch(error){
            router.push({ name: 'error', params: { message: 'エラーが発生しました。' } })
        }
    }

    onMounted(async () => {
      const token = sessionStorage.getItem('spf.refresh_token')
      // ログイン済みか
      if (token !== null && token !== '') {
          await getMe()
      }
    })

    return {
      user
    }
  },
})
