<template>
  <div>
    <component v-bind:is="layout()"></component>
  </div>
</template>

<script>
import DefaultLayout from './components/layout/DefaultLayout'
import SimpleLayout from './components/layout/SimpleLayout'

export default {
  components: {
    DefaultLayout,
    SimpleLayout
  },

  methods: {
    layout() {
      // ログインなどヘッダー有無でレイアウト切り替え
      return this.$route.meta.layout ? this.$route.meta.layout + '-layout' : 'default-layout'
    }
  }
}
</script>

<style>
/* scoped ではなく全体適用、デフォルトは明朝体が強いので無難な指定に変更 */
body {
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
  margin: 0px;
}
</style>