
import { defineComponent, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { ArrowDown } from '@element-plus/icons'
import User from '@/models/user'
import Company from '@/models/company'
import http from "@/modules/httpclient"

export default defineComponent({
  components:{
    ArrowDown
  },
  props:{
    loginUser:{
      type: User
    }
  },
  setup() {
    const route = useRoute()
    // const user = reactive<User>(new User('', '', '', '', [], [], [], 0, '', ''))
    const companies = reactive<Company[]>([])
    const portal = reactive({id:'management', name: '運用管理'})

    // const getMe = async () => {
    //   const res = await http.get('/v1/me')
    //   const data = res.data.user
    //   user.email = data.email
    //   user.displayName = data.displayName
    // }

    const getCompanies = async () => {
      const res = await http.get('/v1/companies')
      companies.splice(0)
      companies.push(new Company('management','運用管理', 'management', '', 0))
      res.data.companies.map((c:any)=>{
        companies.push(new Company(c.companyID, c.companyName, c.companyAlias, c.businessCode, c.isDeleted))
      })
    }

    const setPortal = () => {
      const portalID = sessionStorage.getItem('spf.portal.id')
      if(portalID != null){
        portal.id = portalID
        portal.name = companies[companies.findIndex(i => i.companyID === portalID)].companyName
      }
      else{
        sessionStorage.setItem('spf.portal.id', portal.id)
      }
    }

    const changePortal = (companyID) => {
      sessionStorage.setItem('spf.portal.id',companyID)
      // 見られる情報が変わるので適用するためにリロード
      location.reload()
    }

    onMounted(async () => {
      // TODO: ポータル機能 一旦未実装でコミットするためコメントアウト
      // const token = sessionStorage.getItem('spf.refresh_token')
      // // ログイン済みか
      // if (token !== null && token !== '') {
      //     // await getMe()
      //     await getCompanies()
      //     setPortal()
      // }
    })

    return {
      companies, portal, changePortal
    }
  },
})
