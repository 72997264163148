export default class Company {
    companyID: string
    companyName: string
    companyAlias: string
    businessCode: string
    isDeleted: boolean

    constructor(companyID: string, companyName: string, companyAlias: string, businessCode: string, isDeleted: number) {
        this.companyID = companyID
        this.companyName = companyName
        this.companyAlias = companyAlias
        this.businessCode = businessCode
        this.isDeleted = isDeleted === 1
    }
}