
import { defineComponent, ref } from 'vue'

export default defineComponent({
    setup() {
        const isCollapse = ref(true)
        const domain = sessionStorage.getItem('spf.auth.domain')
        const client = sessionStorage.getItem('spf.auth.clientID')
        const region = sessionStorage.getItem('spf.auth.region')
        const logoutUrl = `https://${domain}.auth.${region}.amazoncognito.com/logout?logout_uri=${process.env.VUE_APP_URL}/logout&client_id=${client}`

        const logout = () => {
            window.location.href = logoutUrl
        }

        return {
            isCollapse, logoutUrl, logout
        }
    },
})
