import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

//import cognito from '@/plugins/auth/cognito'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/maintenance/v1/login',
    name: 'login',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
  },
  {
    path: '/error',
    name: 'error',
    meta: { layout: 'simple' },
    props: true,
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue')
  },
  {
    // ログイン後処理
    path: '/callback',
    name: 'callback',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "callback" */ '../views/Callback.vue')
  },
  // {
  //   // ダッシュボード
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  // },
  {
    // 加盟店管理
    path: '/maintenance/v1/assosiation',
    name: 'maintenanceAssosiation',
    component: () => import(/* webpackChunkName: "maintenanceAssosiation" */ '../views/MaintenanceAssosiation.vue')
  },
  {
    // 加盟店メンテナンス
    path: '/maintenance/v1/company',
    name: 'maintenanceCompany',
    component: () => import(/* webpackChunkName: "maintenanceCompany" */ '../views/MaintenanceCompany.vue')
  },
  {
    // ブランドメンテナンス
    path: '/maintenance/v1/brand',
    name: 'maintenanceBrand',
    component: () => import(/* webpackChunkName: "maintenanceBrand" */ '../views/MaintenanceBrand.vue')
  },
  {
    // 施設メンテナンス
    path: '/maintenance/v1/facility',
    name: 'maintenanceFacility',
    component: () => import(/* webpackChunkName: "maintenanceFacility" */ '../views/MaintenanceFacility.vue')
  },
  {
    // ロケーションメンテナンス
    path: '/maintenance/v1/location',
    name: 'maintenanceLocation',
    component: () => import(/* webpackChunkName: "maintenanceLocation" */ '../views/MaintenanceLocation.vue')
  },
  {
    // 端末メンテナンス
    path: '/maintenance/v1/terminal',
    name: 'maintenanceTerminal',
    component: () => import(/* webpackChunkName: "maintenanceTerminal" */ '../views/MaintenanceTerminal.vue')
  },
  {
    // ユーザーメンテナンス
    path: '/maintenance/v1/users',
    name: 'maintenanceUser',
    component: () => import(/* webpackChunkName: "maintenanceTerminal" */ '../views/MaintenanceUser.vue')
  },
  {
    // 権限メンテナンス
    path: '/maintenance/v1/role',
    name: 'maintenanceRole',
    component: () => import(/* webpackChunkName: "maintenanceTerminal" */ '../views/MaintenanceRole.vue')
  },

  // TODO: 404 
  {
    // 404
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
