import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_TheMenu = _resolveComponent("TheMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("header", null, [
      _createVNode(_component_TheHeader, { loginUser: _ctx.user }, null, 8, ["loginUser"])
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_TheMenu),
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_main, null, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, { loginUser: _ctx.user }, null, 8, ["loginUser"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}